// TODO - make complete implementation of partnerShares condition builder
// At the moment this is simplified partnerShares implementation 
// that only allows to create a share by  BrandId

import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"
import { Redirect } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

//import { settingsActions } from '../../../store/settings';
import { partnerActions } from '../../../store/partners';

import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
// import ReactSelectAsync from '../../../components/ReactBootstrapFromElements/ReactSelectAsyncInput'
import ReactSelectAutocompleteInput from '../../../components/ReactBootstrapFromElements/ReactSelectAutocompleteInput'
import ReactSelectMultiCreateInput from '../../../components/ReactBootstrapFromElements/ReactSelectMultiCreateInput'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  _id: yup.string(),
  name: yup.string().min(2),
  partner: yup.string().required(),
  shareWith: yup.string().required(),
  shareScope: yup.array().of(yup.string()).required(),
  /*
  shareWith: yup.array().of(yup.string()).required(),
  shareScope: yup.object().shape({
    sharedBrands: yup.array().of(yup.object().shape({
      partner: yup.string(),
      brandId: yup.string()
    })),
    sharedProducts: yup.array().of(yup.object().shape({
      partner: yup.string(),
      brandId: yup.string(),
      productId: yup.string(),
      productUuid: yup.string().uuid(),
    })),
    sharedReviews: yup.array().of(yup.object().shape({
      partner: yup.string(),
      brandId: yup.string(),
      productId: yup.string(),
      productUuid: yup.string().uuid(),
      reviewId: yup.string()
    })),
  }).required()
  */
})
.noUnknown(true);

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;

  const onSubmitFormatter = (data) => {
    const shareScope = _.map(data.shareScope, brandId => {
      return { brandId, partner: data.partner }
    })
    const dataFormatted = {
      ...data,
      ...{
        shareWith: [data.shareWith],
        shareScope: {
          sharedBrands: shareScope,
          sharedProducts: shareScope,
          sharedReviews: shareScope
        }
      }
    }
    return onSubmit(dataFormatted)
  }
  
  const { register, formState: { errors }, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  // const partner = watch('partner')

  // console.log('apiKey formState:', getValues())
  // console.log('err', errors);

  const isSuccess = _.get(submitResult, 'status') === 'success'

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmitFormatter)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="name" title="Name" className="mb-4" placeholder="Any descriptive name" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAutocompleteInput 
            disabled={readonly}
            autoFocus={false} 
            name="partner"
            title="Shared By" 
            className="mb-4"
            control={control} 
            errors={errors}

            Actions={partnerActions}
            actionName="partnersList"
            queryParams={{limit: 100}}
            itemsPath="partners_data.partnersList"
            fieldName="partner_title"
            placeholder="Find partner by title"
            isClearable={true}
            selectedValue={item.partner}
          />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAutocompleteInput 
            disabled={readonly}
            autoFocus={false} 
            name="shareWith"
            title="Share With" 
            className="mb-4"
            control={control} 
            errors={errors}

            Actions={partnerActions}
            actionName="partnersList"
            queryParams={{limit: 10}}
            itemsPath="partners_data.partnersList"
            fieldName="partner_title"
            placeholder="Find partner by title"
            isClearable={true}
            selectedValue={item.partner}
          />
        </Col>
      </Row>

      <div className="mb-4">
        <h5>Share by Brand ID</h5>
        <div className="text-gray small">Share Products and Reviews of this Brand</div>
      </div>

      <Row>
        <Col md={6} className="mb-3">
          <ReactSelectMultiCreateInput
            disabled={readonly}
            autoFocus={false} 
            name="shareScope"
            title="Brand IDs" 
            className="mb-4"
            control={control} 
            errors={errors}
            placeholder=""
          />
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
