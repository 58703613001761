import React from "react";
import { Col, Row, Card } from 'react-bootstrap';

import AddButton from './AddButton'

const Component = (props) => {
  const { item } = props;
 
 return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Row>
            <Col xs={6} >
              <span className="mb-4 fw-normal">Points balance</span>
            </Col>
            <Col xs={6} className="text-right">
              <AddButton item={item} />
            </Col>
            <Col xs={12} className="py-3">
              <Row>
                <Col xs={12}>
                  <h4 className="fw-bold">{(item?.memberAccount?.pointsBalance ?? 0).toLocaleString()}</h4>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} className="py-3 pe-1">
                      <span className="fw-normal">Points earned</span>
                    </Col>
                    <Col xs={12}>
                      <h4 className="fw-bold">{(item?.memberAccount?.pointsEarned ?? 0).toLocaleString()}</h4>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} className="py-3 pe-1">
                      <span className="fw-normal">Points redeemed</span>
                    </Col>
                    <Col xs={12}>
                      <h4 className="fw-bold">{(item?.memberAccount?.pointsRedeemed ?? 0).toLocaleString()}</h4>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Component;
