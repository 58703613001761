import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"
import { Redirect } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { settingsActions } from '../../../store/settings';

import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import ReactSelectAsync from '../../../components/ReactBootstrapFromElements/ReactSelectAsyncInput'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  _id: yup.string(),
  partner_name: yup.string().min(2),
  partner_title: yup.string().min(2),
  partner_website: yup.string().min(2),
  partner_account_status: yup.string().oneOf(['active', 'suspended']),
})
.noUnknown(true);

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;
  
  const { register, formState: { errors }, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  const isSuccess = _.get(submitResult, 'status') === 'success'

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="partner_name" title="Partner Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="partner_title" title="Partner Title" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="partner_website" title="Partner webstie" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAsync
            Actions={settingsActions}
            actionName="partnerStatusesList"
            queryParams={{}}
            itemsPath="settings_data.partnerStatusesList"
            fieldId="setting.key"
            fieldName="setting.value"
            placeholder="Type"
            isClearable={true}
            selectedValue={item.partner_account_status}

            disabled={readonly}
            autoFocus={false} 
            name="partner_account_status"
            title="Status" 
            className="mb-4"
            control={control} 
            errors={errors}
          />
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
