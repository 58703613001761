import { combineReducers } from "redux";
import _ from "lodash";

import * as reviewsList from "./reviewsList";
import * as getReview from "./getReview";

const slices = {
    reviewsList,
    getReview
};

const reviewTypes = _.mapValues(slices, (s) => s.slice.types );
const reviewActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const reviewSagas = _(slices).values().map('slice.sagas').flatten().value()
const reviewSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    reviewTypes,
    reviewActions,
    reviewSagas,
    reviewSelectors,
};

export default combineReducers(reducers);