import React from "react";
import { Col, Row, Card } from 'react-bootstrap';

import EditButton from './EditButton'

const Component = (props) => {
  const { item } = props;
  
  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Card.Header className="px-0">
            <Row className="align-items-center">
              <Col>
                <h6>{item.title}</h6>
              </Col>
              <Col className="text-end">
                <EditButton item={item} />
              </Col>
            </Row>
          </Card.Header>
          <Row>
            <Col xs={12} className="py-2">
              <Row className="py-1">
                <Col xs={6} className="py-1">
                  <span className="fw-bold">Status</span>
                </Col>
                <Col xs={6} className="py-1">
                  <span className={`fw-normal ${(item.status === 'Active') && 'text-success'}`}>{item.status}</span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={6} className="py-1">
                  <span className="fw-bold">Type</span>
                </Col>
                <Col xs={6} className="py-1">
                  <span className="fw-normal">{item.earningSettingType}</span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={6} className="py-1">
                  <span className="fw-bold">Base points</span>
                </Col>
                <Col xs={6} className="py-1">
                  <span className="fw-normal">{item.basePoints}</span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={6} className="py-1">
                  <span className="fw-bold">Activity label</span>
                </Col>
                <Col xs={6} className="py-1">
                  <span className="fw-normal">{item.baseConditionTitle || 'No label'}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Component;
