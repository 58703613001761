import React from "react";
import { useParams } from 'react-router-dom';

import ListPermissions from './ListPermissions'
import List from './List'

const Component = () => {
    const { _id } = useParams()
    return (
        <>
            <ListPermissions>
                <List review={_id} showTableSettings={false} showColumns={[0,1,2]} />
            </ListPermissions>
        </>
    );
};

export default Component;
