import React from "react";
import { Col, Row, Card, Badge } from 'react-bootstrap';

import EditButton from './EditButton'
import { Link } from 'react-router-dom';
import { Routes } from "../../../routes";

const Component = (props) => {
  const { item } = props;
  
  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Card.Header className="px-0">
            <Row className="align-items-center">
              <Col>
                <h6>{item.name || 'No name'}</h6>
              </Col>
              <Col className="text-end">
                <EditButton item={item} />
              </Col>
            </Row>
          </Card.Header>
          <Row>
            <Col xs={12} className="py-2">
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Shared With</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  {item.shareWith.map((partner, i) => (
                    <div key={i} className="fw-normal text-blue text-decoration-none">
                      <Card.Link as={Link} to={Routes.Partner.path.replace(':_id',partner)}>
                        <span className="fw-bold text-blue">{partner}</span>
                      </Card.Link>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Shared Brands Filter</span>
                </Col>
                <Col xs={12} md={8} className="py-1 list-underline-separated">
                  {item.shareScope.sharedBrands.map((brandShare, i) => (
                    <div key={i} className="fw-normal">
                      <Badge bg="success mx-1">Brand ID: {brandShare?.brandId}</Badge>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Shared Products Filter</span>
                </Col>
                <Col xs={12} md={8} className="py-1 list-underline-separated">
                  {item.shareScope.sharedProducts.map((productShare, i) => (
                    <div key={i} className="fw-normal">
                      <span>
                        {productShare?.brandId && <Badge bg="success mx-1">Brand ID: {productShare?.brandId}</Badge>}
                        {productShare?.productId && <Badge bg="success mx-1">Product ID: {productShare?.productId}</Badge>}
                        {productShare?.productUuid && <Badge bg="success mx-1">Product UUID: {productShare?.productUuid}</Badge>}
                      </span>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Shared Reviews Filter</span>
                </Col>
                <Col xs={12} md={8} className="py-1 list-underline-separated">
                  {item.shareScope.sharedReviews.map((reviewShare, i) => (
                    <div key={i} className="fw-normal">
                      <span>
                        {reviewShare?.brandId && <Badge bg="success mx-1">Brand ID: {reviewShare?.brandId}</Badge>}
                        {reviewShare?.productId && <Badge bg="success mx-1">Product ID: {reviewShare?.productId}</Badge>}
                        {reviewShare?.productUuid && <Badge bg="success mx-1">Product UUID: {reviewShare?.productUuid}</Badge>}
                      </span>
                    </div>
                  ))}
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Owner</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  <Card.Link as={Link} to={Routes.Partner.path.replace(':_id',item.partner)}>
                    <span className="fw-bold text-blue">{item.partner}</span>
                  </Card.Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Component;
