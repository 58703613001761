import { combineReducers } from "redux";
import _ from "lodash";

import * as earningSettingActionsList from "./earningSettingActionsList";
import * as getEarningSettingAction from "./getEarningSettingAction";
import * as createEarningSettingActionAction from "./createEarningSettingAction";
import * as editEarningSettingAction from "./editEarningSettingAction";

const slices = {
    earningSettingActionsList,
    createEarningSettingActionAction,
    getEarningSettingAction,
    editEarningSettingAction,
};

const earningSettingActionTypes = _.mapValues(slices, (s) => s.slice.types );
const earningSettingActionActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const earningSettingActionSagas = _(slices).values().map('slice.sagas').flatten().value()
const earningSettingActionSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    earningSettingActionTypes,
    earningSettingActionActions,
    earningSettingActionSagas,
    earningSettingActionSelectors,
};

export default combineReducers(reducers);