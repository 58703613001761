import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getWithExpiry } from '../../helpers/localStorage'
import { earningSettingActionActions as Actions } from '../../store/earningSettingsActions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import { useParams } from "react-router-dom";

import { Row, Col, Card, Table } from "react-bootstrap";
import AddButton from './AddButton'

import Alerts from '../../components/multitools/Alerts'
import Preloader from "../../components/Preloader";


const Component = (props) => {
  let { _id } = useParams();
  const { earningSettingId } = props
  const name = 'earningSettingActionsList';
  const stateName = 'earning_settings_actions_data';

  const dispatch = useDispatch();
  const istemsList = payload => dispatch(Actions[name](payload))
  const itemsListRes = useSelector(state => state[stateName][name])
  const status = itemsListRes?.status ?? ''
  const error = itemsListRes?.data?.error ?? 'Error'
  const items = itemsListRes?.data?.items ?? []
  const count = itemsListRes?.data?.meta?.count ?? 0

  const limit = '500'
  const offset = '0'

  useEffect(() => {
      const accessToken = getWithExpiry('accessToken');
      if (accessToken) {
          const payload = {
            data: {
              limit,
              offset,
              earningSettingId,
            },
            token: accessToken,
          }
          istemsList(payload);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // is loading
  if (['', 'initial', 'loading'].includes(status)) {
    return <Preloader show={true} />
  }

  if (['fail', 'error'].includes(status)) {
    return (
      <div className="mt-3 mb-3">
        <Alerts alerts={error} />
      </div>
    )
  }

  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Card.Header className="px-0">
            <Row className="align-items-center">
              <Col>
                <h6>Additional actions</h6>
              </Col>
              <Col className="text-end">
                <AddButton />
              </Col>
            </Row>
          </Card.Header>
          <Table hover className="user-table align-items-center">
              <thead>
                  <tr>
                      <th className="border-bottom px-0">Label</th>
                      <th className="border-bottom">Points</th>
                  </tr>
              </thead>
              <tbody>
                {
                  _(items)
                  .map(function(item, i) {
                    return (
                      <tr key={item._id} data-i={i}>
                        <td className="px-0">
                          <Card.Link as={Link} to={Routes.EditEarningSettingsAction.path.replace(':_settingId', _id).replace(':_id',item._id)}>
                            <span className="fw-normal text-blue">{item.title}</span>
                          </Card.Link>
                        </td>
                        <td><span className="fw-normal">{item.points}</span></td>
                      </tr>
                    );
                  })
                  .value()
                }
                {count === 0 && (
                  <tr>
                    <td colSpan="2"><span className="fw-normal">No entries to show</span></td>
                  </tr>
                )}
              </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          </Card.Footer>
        </Card.Body>
    </Card>
    </>
  );
}

export default Component;