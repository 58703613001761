import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Routes } from "../../../routes";

import CheckPermissions from '../../../components/multitools/CheckPermissions'
import DeletionModal from '../../../components/modals/DeletionModal'

import { apiKeyActions as Actions } from '../../../store/apiKeys'

const Component = (props) => {
    const { item } = props
    // this component requirements
    const [componentId] = useState(`DeleteApiKeyButton_${Date.now()}`);
    const requirements = {
        componentId,
        auth: true,
        endpoints: [
            { method: 'DELETE', path: '/api-keys/:_id' },
        ]
    }

    const BtnRemove = (
      <Button variant="danger" size="sm" as={Link} to="#">
        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
      </Button>
    )

    return (   
      <CheckPermissions requirements={requirements} childrenIfNoPermission={null}>
        <DeletionModal
          Actions={Actions}
          actionName="deleteApiKey"
          stateName="api_keys_data"
          item={item}
          btn={BtnRemove}
          modalHeading="Delete API Key"
          modalBody="Are you sure?"
          redirectAfterTo={Routes.ApiKeys.path}
        />
      </CheckPermissions>
    );
};

export default Component;
