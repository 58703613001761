
import React, { useState } from "react";
import { Col, Row, Breadcrumb } from 'react-bootstrap';
import { Routes } from "../../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import CheckPermissions from '../../components/multitools/CheckPermissions'
import WidgetRecentTransactions from '../transactions/WidgetRecentTransactions'
import WidgetMembersCount from '../members/WidgetMembersCount'
import WidgetReviewsCount from '../reviews/WidgetReviewsCount'
import WidgetTransactionsCount from '../transactions/WidgetTransactionsCount'
import WidgetPartnersCount from '../partners/WidgetPartnersCount'

const Component = () => {  
  // this component requirements
  const [componentId] = useState(`DashboardPage_${Date.now()}`);
  const requirements = {
      componentId,
      auth: true,
      endpoints: [
          { method: 'GET', path: '/user' },
      ]
  }
  return (
    <>
      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="mb-4 mb-lg-0">
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item href={Routes.GeneralLanding.path}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                  <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Dashboard</h4>
              <p className="mb-0">Latest data at a glance</p>
          </div>
      </div>

      <CheckPermissions requirements={requirements}>
        <main>
          <section className="mb-5 pb-5">
        
            <Row>
              <Col xs={12} xl={12} className="mb-4">
                <Row>
                  <Col xs={12} xl={4}>
                    <Row>
                      <Col xs={12} className="mb-4">
                        <WidgetPartnersCount />
                      </Col>

                      <Col xs={12} className="mb-4">
                        <WidgetMembersCount />
                      </Col>

                      <Col xs={12} className="mb-4">
                        <WidgetReviewsCount />
                      </Col>

                      <Col xs={12} className="mb-4">
                        <WidgetTransactionsCount />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} xl={8} className="mb-4">
                    <Row>
                      <Col xs={12} className="mb-4">
                        <WidgetRecentTransactions />
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </Col>
            </Row>

          </section>
        </main>
      </CheckPermissions>
    </>
    
  );
};

export default Component;
