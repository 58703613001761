import { combineReducers } from "redux";
import _ from "lodash";

import * as membersList from "./membersList";
import * as membersListAutocomplete from "./membersListAutocomplete";
import * as getMember from "./getMember";
import * as createMember from "./createMember";
import * as editMember from "./editMember";

const slices = {
    membersList,
    membersListAutocomplete,
    createMember,
    getMember,
    editMember,
};

const memberTypes = _.mapValues(slices, (s) => s.slice.types );
const memberActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const memberSagas = _(slices).values().map('slice.sagas').flatten().value()
const memberSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    memberTypes,
    memberActions,
    memberSagas,
    memberSelectors,
};

export default combineReducers(reducers);