import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import _ from "lodash"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { roleActions } from '../../../store/roles';
import { partnerActions } from '../../../store/partners';

import EmailInput from '../../../components/ReactBootstrapFromElements/EmailInput'
import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import ReactSelectAsync from '../../../components/ReactBootstrapFromElements/ReactSelectAsyncInput'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  firstName: yup.string().min(2).label('First Name'),
  lastName: yup.string().min(2).label('Last Name'),
  email: yup.string().email().required().label('Email'),
  role: yup.string().required('Can not be empty').label('Role'),
  partner: yup.string().required('Can not be empty').label('Partner'),
});

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;
  
  const { register, formState: { errors }, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  const isSuccess = _.get(submitResult, 'status') === 'success'

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="firstName" title="First Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="lastName" title="Last Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <EmailInput disabled={readonly} autoFocus={false} name="email" title="Email" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAsync 
            disabled={readonly}
            autoFocus={false} 
            name="role"
            title="Role" 
            className="mb-4"
            control={control} 
            errors={errors}

            Actions={roleActions}
            actionName="rolesList"
            queryParams={{roleOwnerTypes: 'User'}}
            itemsPath="roles_data.rolesList"
            fieldName="roleName"
            placeholder="Select role"
            isClearable={true}
            selectedValue={item.role}
          />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAsync 
            disabled={readonly}
            autoFocus={false} 
            name="partner"
            title="Partner" 
            className="mb-4"
            control={control} 
            errors={errors}

            Actions={partnerActions}
            actionName="partnersList"
            queryParams={{limit: 100}}
            itemsPath="partners_data.partnersList"
            fieldName="partner_title"
            placeholder="Select partner"
            isClearable={true}
            selectedValue={item.partner}
          />
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
