import React, { useState } from "react";

import CheckPermissions from '../../components/multitools/CheckPermissions'

const Component = (props) => {
    // this component requirements
    const [componentId] = useState(`EarningSettingActionsList_${Date.now()}`);
    const requirements = {
        componentId,
        auth: true,
        endpoints: [
            { method: 'GET', path: '/earning-settings-actions' },
        ]
    }

    return (
      <CheckPermissions {...props} requirements={requirements} childrenIfNoPermission={null} />
    );
};

export default Component;
