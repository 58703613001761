import { combineReducers } from "redux";
import _ from "lodash";

import * as earningSettingsList from "./earningSettingsList";
import * as getEarningSetting from "./getEarningSetting";
import * as createEarningSetting from "./createEarningSetting";
import * as editEarningSetting from "./editEarningSetting";

const slices = {
    earningSettingsList,
    createEarningSetting,
    getEarningSetting,
    editEarningSetting,
};

const earningSettingTypes = _.mapValues(slices, (s) => s.slice.types );
const earningSettingActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const earningSettingSagas = _(slices).values().map('slice.sagas').flatten().value()
const earningSettingSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    earningSettingTypes,
    earningSettingActions,
    earningSettingSagas,
    earningSettingSelectors,
};

export default combineReducers(reducers);