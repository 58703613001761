// ConditionBuilder fields
export const equityOperators = [
  {name: '=', label: 'is'},
  {name: '!=', label: 'is not'}
]
export const numericOperators = [
  {name: '=', label: 'is'},
  {name: '!=', label: 'is not'},
  {name: '>', label: 'more than'},
  {name: '<', label: 'less than'},
  {name: '>=', label: 'more or equal'},
  {name: '<=', label: 'less or equal'}
]
export const dateOperators = [
  {name: '=', label: 'on'},
  {name: '!=', label: 'is not on'},
  {name: '>', label: 'after'},
  {name: '<', label: 'before'},
  {name: '>=', label: 'on or later'},
  {name: '<=', label: 'earlier or on'}
]
export const textOperators = [
  { name: '=', label: 'is' },
  { name: '!=', label: 'is not' },
  { name: 'contains', label: 'contains' },
  { name: 'beginsWith', label: 'begins with' },
  { name: 'endsWith', label: 'ends with' },
  { name: 'doesNotContain', label: 'does not contain' },
  { name: 'doesNotBeginWith', label: 'does not begin with' },
  { name: 'doesNotEndWith', label: 'does not end with' },
  { name: 'null', label: 'is null' },
  { name: 'notNull', label: 'is not null' },
  { name: 'in', label: 'in' },
  { name: 'notIn', label: 'not in' },
  { name: 'between', label: 'between' },
  { name: 'notBetween', label: 'not between' },
];

export const fields = [
  { name: 'reviewStatus', label: 'Review status', operators: equityOperators, valueEditorType: 'radio', defaultValue: 'published', values: [{name: 'published', label: 'Published'}, {name: 'pending', label:'Pending'}] },
  { name: 'reviewTitle', label: 'Review title', operators: textOperators },
  { name: 'reviewComment', label: 'Review comment', operators: textOperators },
  { name: 'reviewDate', label: 'Review date', inputType: 'date', operators: dateOperators },
  { name: 'reviewScore', label: 'Review score', inputType: 'number', operators: numericOperators },
  { name: 'reviewCommentWordsCount', label: 'Review words count', inputType: 'number', operators: numericOperators },
  { name: 'reviewImagesCount', label: 'Review images count', inputType: 'number', operators: numericOperators },

  { name: 'productId', label: 'Product id', operators: textOperators },
  { name: 'productUuid', label: 'Product UUID', operators: textOperators },
  { name: 'productWebsite', label: 'Product url', operators: textOperators },
  { name: 'productTmbUrl', label: 'Product url at Tellmebaby', operators: textOperators },
  { name: 'product.productIsPremium', label: 'Product is premium listing', valueEditorType: 'checkbox', defaultValue: false, operators: equityOperators },
  { name: 'product.productBonusExcluded', label: 'Product excluded from bonus', valueEditorType: 'checkbox', defaultValue: false, operators: equityOperators },
  { name: 'productIsGifted', label: 'Is gifted product?', valueEditorType: 'checkbox', defaultValue: false, operators: equityOperators },

  { name: 'brandName', label: 'Brand name', operators: textOperators },
  { name: 'brandId', label: 'Brand id', operators: textOperators },

  { name: 'member.memberAccount.reviewsCount', label: 'Member reviews count', inputType: 'number', operators: numericOperators},
  { name: 'member.memberAccount.pointsEarned', label: 'Member points earned', inputType: 'number', operators: numericOperators},
 
  // samples:
  // { name: 'isDev', label: 'Is a Developer?', valueEditorType: 'checkbox', defaultValue: true },
  // { name: 'gender', label: 'Gender', valueEditorType: 'radio', defaultValue: null, values: [{name: 'male', label: 'Male'}, {name: 'female', label:'Female'}] },
  // { name: 'type', label: 'Type', valueEditorType: 'select', defaultValue: 'active', values: [{name: 'Active', label: 'Active'}, {name: 'Inactive', label:'Inactive'}] }
];
