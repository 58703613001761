import React from "react";
import { Col, Row, Card } from 'react-bootstrap';
import moment from 'moment';

import EditButton from './EditButton'

const Component = (props) => {
  const { item, data } = props;
  const userName = item.displayName || (`${item?.firstName ?? ''} ${item?.lastName ?? ''}`).trim()
  const AddLineJoined = [item?.address?.suburb, item?.address?.state, item?.address?.postCode].join(' ')

  const dates = [];
  if( item && item.createdAt ) {
    dates.push( moment(item.createdAt) );
  }
  if( data.firstTransactionDate ) {
    dates.push( moment(data.firstTransactionDate) );
  }
  const minDate = moment.min( dates );

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Row>
            <Col xs={6} >
              <span className="mb-4 fw-normal">Profile</span>
            </Col>
            <Col xs={6} className="text-right">
              <EditButton item={item} />
            </Col>
            <Col xs={12} className="py-3">
              <Row>
                <Col xs={12}>
                  <span className="fw-bold">{userName}</span>
                </Col>
                <Col xs={12}>
                  <span className="fw-normal">{item?.address?.street}</span>
                </Col>
                <Col xs={12}>
                  <span className="fw-normal">{AddLineJoined}</span>
                </Col>
                <Col xs={12}>
                  <span className="fw-normal text-blue text-decoration-none">
                    <a href={`mailto:${item?.email}`} rel="noopener noreferrer">
                      {item?.email}
                    </a>
                  </span>
                </Col>
                <Col xs={12}>
                  <span className="fw-normal">{item?.address?.phone}</span>
                </Col>
                <Col xs={12} className="pt-3">
                  <span className="fw-normal">Member for { minDate.fromNow(true)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Component;
