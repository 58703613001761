import { combineReducers } from "redux";
import _ from "lodash";

import * as partnerSharesList from "./partnerSharesList";
import * as getPartnerShare from "./getPartnerShare";
import * as createPartnerShare from "./createPartnerShare";
import * as editPartnerShare from "./editPartnerShare";

const slices = {
    partnerSharesList,
    createPartnerShare,
    getPartnerShare,
    editPartnerShare,
};

const partnerShareTypes = _.mapValues(slices, (s) => s.slice.types );
const partnerShareActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const partnerShareSagas = _(slices).values().map('slice.sagas').flatten().value()
const partnerShareSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    partnerShareTypes,
    partnerShareActions,
    partnerShareSagas,
    partnerShareSelectors,
};

export default combineReducers(reducers);