
export const Routes = {
    //portal pages
    GeneralLanding: { path: "/" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:_token" },
    VerifyEmail: { path: "/verify-request/:_token" },
    Dashboard: { path: "/dashboard" },

    AddUser: { path: "/users/create" },
    EditUser: { path: "/users/:_id/edit" },
    User: { path: "/users/:_id" },
    Users: { path: "/users" },
    
    AddMember: { path: "/members/create" },
    EditMember: { path: "/members/:_id/edit" },
    Member: { path: "/members/:_id" },
    Members: { path: "/members" },
    AddMemberPoints: { path: "/members/:_id/edit-points" },

    Partners: { path: "/partners" },
    AddPartner: { path: "/partners/create" },
    Partner: { path: "/partners/:_id" },
    EditPartner: { path: "/partners/:_id/edit" },
    // DeletePartner: { path: "/partners/:_id/delete" },

    ApiKeys: { path: "/api-keys" },
    AddApiKey: { path: "/api-keys/create" },
    ApiKey: { path: "/api-keys/:_id" },
    EditApiKey: { path: "/api-keys/:_id/edit" },
    // DeleteApiKey: { path: "/api-keys/:_id/delete" },

    PartnerShares: { path: "/partner-shares" },
    AddPartnerShare: { path: "/partner-shares/create" },
    PartnerShare: { path: "/partner-shares/:_id" },
    EditPartnerShare: { path: "/partner-shares/:_id/edit" },

    EarningSettings: { path: "/earning-settings" },
    AddEarningSetting: { path: "/earning-settings/create" },
    EarningSetting: { path: "/earning-settings/:_id" },
    EditEarningSetting: { path: "/earning-settings/:_id/edit" },
    DeleteEarningSetting: { path: "/earning-settings/:_id/delete" },

    AddEarningSettingsAction: { path: "/earning-settings/:_settingId/earning-settings-actions/create" },
    EditEarningSettingsAction: { path: "/earning-settings/:_settingId/earning-settings-actions/:_id/edit" },

    MyProfile: { path: "/profile" },
    EditMyProfile: { path: "/profile/edit" },

    AddBrand: { path: "/brands/create" },
    Brands: { path: "/brands" },

    AddProduct: { path: "/products/create" },
    Products: { path: "/products" },
    Product: { path: "/products/:_id" },
    
    AddReview: { path: "/reviews/create" },
    EditReview: { path: "/reviews/:_id/edit" },
    Review: { path: "/reviews/:_id" },
    Reviews: { path: "/reviews" },

    // pages
    DashboardOverview: { path: "/dashboard/overview" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    
    
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Forbidden: { path: "/403" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};