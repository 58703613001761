import { all } from 'redux-saga/effects';

import { userSagas } from './users';
import { memberSagas } from './members';
import { roleSagas } from './roles';
import { partnerSagas } from './partners';
import { brandSagas } from './brands';
import { reviewSagas } from './reviews';
import { productSagas } from './products';
import { settingsSagas } from './settings';
import { transactionSagas } from './transactions';
import { earningSettingSagas } from './earningSettings';
import { earningSettingActionSagas } from './earningSettingsActions';
import { apiKeySagas } from './apiKeys';
import { partnerShareSagas } from './partnerShares';

export default function* rootSaga() {
    yield all([
        ...userSagas,
        ...memberSagas,
        ...roleSagas,
        ...partnerSagas,
        ...brandSagas,
        ...productSagas,
        ...reviewSagas,
        ...settingsSagas,
        ...transactionSagas,
        ...earningSettingSagas,
        ...earningSettingActionSagas,
        ...apiKeySagas,
        ...partnerShareSagas,
    ])
}
