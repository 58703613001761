import React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../../routes";

import FormPermissions from './FormPermissions'
import FormLoader from './FormLoader'

const Component = () => {
    return (
        <>
            <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="mb-4 mb-lg-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item href={Routes.Dashboard.path}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>Settings</Breadcrumb.Item>
                        <Breadcrumb.Item href={Routes.Partners.path}>Partners</Breadcrumb.Item>
                        <Breadcrumb.Item active>Partner</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Partner profile</h4>
                </div>
            </div>
            <Row>
                <Col xs={12} xl={5}>
                    <Row>
                        <Col xs={12}>
                            <FormPermissions>
                                <FormLoader />
                            </FormPermissions>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
        </>
    );
};

export default Component;
