import React from "react";
import { Col, Row, Card, Image, Button, Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getWithExpiry } from '../helpers/localStorage'

import { Routes } from "../routes";
import TellmeBabyMiniLogoPink from "../assets/img/tellme-brand-logos/mini_pink.svg";


const Component = () => {
  const accessToken = getWithExpiry('accessToken')
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('accessToken')
    document.location.reload()
  }

  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={Link} to={Routes.GeneralLanding.path} className="me-lg-3 d-flex align-items-center">
            <Image src={TellmeBabyMiniLogoPink} />
            <span className="ms-2 d-none d-md-inline">Tell Me Baby</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link target="_blank" href="https://tellmebaby.com.au">AU website</Nav.Link>
                <Nav.Link target="_blank" href="https://tellmebaby.uk">UK website</Nav.Link>
                {!accessToken && (
                  <Nav.Link target="_blank" href="https://tellmebaby.com.au/contact" className="d-sm-none d-xl-inline">Request access</Nav.Link>
                )}
                {accessToken && (
                  <Nav.Link as={Link} to={Routes.Dashboard.path} className="d-sm-none d-xl-inline">Dashboard</Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
            {!accessToken && (
              <Button as={Link} to={Routes.Signin.path} variant="outline-white" className="ms-3">Login</Button>
            )}
            {accessToken && (
              <Button type="button" variant="outline-white" className="ms-3" onClick={handleLogout}>Logout</Button>
            )}
          </div>
        </Container>
      </Navbar>
      
      <div className="section pt-5">
        <Container>
          <Row className="justify-content-center align-items-center mt-5 mt-lg-6">
            <Col xs={6} md={3} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <Card.Link href="https://tellmebaby.com.au" target="_blank" className="d-flex justify-content-center">
                  <Image src={TellmeBabyMiniLogoPink} height={35} className="d-block" alt="Themesberg Logo" style={{ marginRight: '5px', marginTop: '5px' }} />
                </Card.Link>
              </div>
              <h3 className="fw-light">Tell Me Baby</h3>
              <p className="text-gray">Loyalty and Partner portal</p>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={Link} to={Routes.GeneralLanding.path} className="me-lg-3 mb-3 d-flex align-items-center">
                <Image src={TellmeBabyMiniLogoPink} />
                <span className="ms-2">Tell Me Baby</span>
              </Navbar.Brand>
              <p>Tell Me Baby is quickly expanding into Australia’s most interactive, respected and talked about digital parenting communities.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0 offset-md-2">
              <span className="h5">Tell Me Baby</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/about-us/">About Us</Card.Link></li>
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/terms-conditions/">Terms and conditions</Card.Link></li>
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/privacy-policy/">Privacy policy</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">For Brands</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/brand-opportunities/">Brand opportunities</Card.Link></li>
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/brands/">Brands</Card.Link></li>
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/list-your-own-product/">List your own product</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Contact</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link target="_blank" href="https://tellmebaby.com.au/contact/">Contact Us</Card.Link></li>
              </ul>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Tell Me Group Limited <span className="current-year">{new Date().getFullYear()}</span>. All Rights Reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Component;
