import React, { useState } from "react";

import CheckPermissions from '../../../components/multitools/CheckPermissions'

const Component = (props) => {
    // this component requirements
    const [componentId] = useState(`AddPartnerSharePage_${Date.now()}`);
    const requirements = {
        componentId,
        auth: true,
        endpoints: [
            { method: 'POST', path: '/partner-shares' },
        ]
    }

    return (
      <CheckPermissions {...props} requirements={requirements} />
    );
};

export default Component;
