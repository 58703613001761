import { combineReducers } from 'redux';

import usersRootReducer from './users';
import membersRootReducer from './members';
import rolesRootReducer from './roles';
import partnersRootReducer from './partners';
import brandsRootReducer from './brands';
import productsRootReducer from './products';
import reviewsRootReducer from './reviews';
import settingsRootReducer from './settings';
import transactionsRootReducer from './transactions';
import earningSettingsRootReducer from './earningSettings';
import earningSettingActionsRootReducer from './earningSettingsActions';
import apiKeysRootReducer from './apiKeys';
import partnerSharesRootReducer from './partnerShares';

const rootReducer = combineReducers({
    users_data: usersRootReducer,
    members_data: membersRootReducer,
    roles_data: rolesRootReducer,
    partners_data: partnersRootReducer,
    brands_data: brandsRootReducer,
    products_data: productsRootReducer,
    reviews_data: reviewsRootReducer,
    settings_data: settingsRootReducer,
    transactions_data: transactionsRootReducer,
    earning_settings_data: earningSettingsRootReducer,
    earning_settings_actions_data: earningSettingActionsRootReducer,
    api_keys_data: apiKeysRootReducer,
    partner_shares_data: partnerSharesRootReducer,
});

export default rootReducer;