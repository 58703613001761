import { combineReducers } from "redux";
import _ from "lodash";

import * as rolesList from "./rolesList";

const slices = {
    rolesList,
};

const roleTypes = _.mapValues(slices, (s) => s.slice.types );
const roleActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const roleSagas = _(slices).values().map('slice.sagas').flatten().value()
const roleSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    roleTypes,
    roleActions,
    roleSagas,
    roleSelectors,
};

export default combineReducers(reducers);