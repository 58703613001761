import React, { useEffect } from "react";
import { Col, Row, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import RecordStats from "../../../components/widgets/RecordStats";
import _ from "lodash"

import { Routes } from "../../../routes";

import { getWithExpiry } from '../../../helpers/localStorage'
import { useDispatch, useSelector } from 'react-redux';
import { userActions as Actions } from '../../../store/users';

import Form from '../_components/Form'

//import userAvatar from "../../../assets/img/avatar/user-avatar.jpg"

const Component = (props) => {
  const { readonly, item } = props;
  
  const actionName = 'createUser';
  const actionStateSelector = state => state.users_data[actionName];
  
  const dispatch = useDispatch();
  const formAction = payload => dispatch(Actions[actionName](payload));
  const actionReset = () => dispatch(Actions[`${actionName}Reset`]());
  const submitResult = useSelector(actionStateSelector);
  const userId = _.get(submitResult, 'data.user._id');

  useEffect(() => {
    actionReset()
    return actionReset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = data => {
    const accessToken = getWithExpiry('accessToken')
    if (!accessToken) {
      document.location.reload()
    }
    const payload = {
      data,
      token: accessToken,
      id: data._id,
    }
    formAction(payload);
  }

  if (userId) {
    return (
      <Redirect to={Routes.User.path.replace(':_id',userId)} />
    )
  }
  
  return (
    <>
      {/*
      import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
      import { faBoxOpen, faChevronDown, faRocket } from '@fortawesome/free-solid-svg-icons';
      import { Dropdown } from 'react-bootstrap';
      */}
      {/*
      {!readonly && (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-flex">
            <Dropdown>
              <Dropdown.Toggle as={Button} variant="primary">
                Actions
                <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown} /></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                <Dropdown.Item>
                  <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Edit
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item>
                  <FontAwesomeIcon icon={faRocket} className="text-success me-2" /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
      */}

      <Row>
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form readonly={readonly} item={item} onSubmit={onSubmit} submitResult={submitResult} />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <RecordStats {...submitResult?.data} />
            </Col>
          </Row>
          {/*
            <Row>
              <Col xs={12}>
                <ProfileCardWidget />
              </Col>
              <Col xs={12}>
                <ChoosePhotoWidget
                  title="Select profile photo"
                  photo={userAvatar}
                />
              </Col>
            </Row>
          */}
        </Col>
      </Row>
    </>
  );
};

export default Component;
