import React from "react";

import ListPermissions from './ListPermissions'
import List from './List'

const Component = () => {
    return (
        <>
            <ListPermissions>
                <List showTableSettings={false} showColumns={[0,1,2,4]} showPagination={false} title="Recent Transactions" isCondensed={true} />
            </ListPermissions>
        </>
    );
};

export default Component;
