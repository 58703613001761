import React from "react";

import FormPermissions from './FormPermissions'
import FormLoader from './FormLoader'

const Component = () => {
    return (
        <>
            <FormPermissions>
                <FormLoader />
            </FormPermissions>
        </>
    );
};

export default Component;
