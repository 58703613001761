import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getWithExpiry } from '../../helpers/localStorage'
import { transactionActions as Actions } from '../../store/transactions';
import { settingsActions } from '../../store/settings';
import { useQuery } from "../../helpers/useQuery";
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

import { Button, ButtonGroup, Row, Col, InputGroup, Form, Dropdown, Card, Table, Nav } from "react-bootstrap";
import PaginationTool from '../../components/multitools/PaginationTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';

import ReactSelectAsync from '../../components/multitools/ReactSelectAsync'

import Alerts from '../../components/multitools/Alerts'
import Preloader from "../../components/Preloader"
import EarningRecordItem from "./EarningRecordItem"


const Component = (props) => {
  const { user, review, showTableSettings, showColumns, showPagination, title, isCondensed } = props
  const { query, setQuery, history } = useQuery();
  const name = 'transactionsList';

  const dispatch = useDispatch();
  const istemsList = payload => dispatch(Actions[name](payload))
  const itemsListRes = useSelector(state => state.transactions_data[name])
  const status = itemsListRes?.status ?? ''
  const error = itemsListRes?.data?.error ?? 'Error'
  const items = itemsListRes?.data?.items ?? []
  const count = itemsListRes?.data?.meta?.count ?? 0
  const countTotal = itemsListRes?.data?.meta?.countTotal ?? 0

  const limit = query.get('limit') ?? '10'
  const offset = query.get('offset') ?? '0'
  const [search, setSearch] = useState(query.get('search') ?? '')

  const transactionType = query.get('transactionType') ?? ''

  useEffect(() => {
    const accessToken = getWithExpiry('accessToken');
    if (accessToken) {
      const payload = {
        data: {
          limit,
          offset,
          search,
          transactionType
        },
        token: accessToken,
      }
      if (user) {
        payload.data.user = user
      }
      if (review) {
        payload.data.review = review
      }
      istemsList(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, review, history.location.search]);

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    setQuery({ search, offset: 0 })
  }

  // is loading
  if (['', 'initial', 'loading'].includes(status)) {
    return <Preloader show={true} />
  }

  if (['fail', 'error'].includes(status)) {
    return (
      <div className="mt-3 mb-3">
        <Alerts alerts={error} />
      </div>
    )
  }

  return (
    <>
      {showTableSettings !== false && (
        <div className="table-settings mb-2 mb-lg-4">
          <Row className="justify-content-between align-items-center">
            <Col xs={12} lg={7} className="d-flex py-2 pe-lg-3">
              <Form onSubmit={handleSearchSubmit} noValidate={true} className="w-100">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" value={search} onChange={event => {
                    setSearch(event.target.value);
                  }} />
                  <Button variant="outline-primary" type="submit">
                    Search
                  </Button>
                </InputGroup>
              </Form>
            </Col>

            <Col xs={12} lg={4} className="d-flex py-2 pe-lg-3">
              <ReactSelectAsync
                Actions={settingsActions}
                actionName="transactionTypesList"
                queryParams={{}}
                itemsPath="settings_data.transactionTypesList"
                fieldId="setting.key"
                fieldName="setting.value"
                placeholder="All types"
                isClearable={true}
                onChange={(item) => setQuery({ transactionType: item?.value ?? '', offset: 0 })}
                selectedValue={transactionType}
              />
            </Col>

            <Col xs={12} lg={1} className="text-end py-2">
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                  <span className="icon icon-sm icon-gray">
                    <FontAwesomeIcon icon={faSlidersH} />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right">
                  <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                  <Dropdown.Item className="d-flex fw-bold" onClick={() => setQuery({ limit: 10, offset: 0 })}>
                    10
                    {limit === '10' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 20, offset: 0 })}>
                    20
                    {limit === '20' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 50, offset: 0 })}>
                    50
                    {limit === '50' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 100, offset: 0 })}>
                    100
                    {limit === '100' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </div>
      )}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        {title && (
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                <h5 className="mb-0">{title}</h5>
              </Col>
              <Col className="text-end">
                <Button variant="secondary" size="sm" as={Link} to={Routes.Transactions.path}>
                  See all
                </Button>
              </Col>
            </Row>
          </Card.Header>
        )}

        <Card.Body className={isCondensed ? 'p-0' : ''}>
          <Table hover>
            <thead className={isCondensed ? 'thead-light' : ''}>
              <tr>
                {(!showColumns || showColumns?.includes(0)) && (
                  <th className="border-bottom">Date</th>
                )}
                {(!showColumns || showColumns?.includes(1)) && (
                  <th className="border-bottom">Event</th>
                )}
                {(!showColumns || showColumns?.includes(2)) && (
                  <th className="border-bottom">Points</th>
                )}
                {(!showColumns || showColumns?.includes(3)) && (
                  <th className="border-bottom">Balance</th>
                )}
                {(!showColumns || showColumns?.includes(4)) && (
                  <th className="border-bottom">Member</th>
                )}
              </tr>
            </thead>
            <tbody>
              {
                _(items)
                  .map(function (item, i) {
                    const userName = (`${item?.user?.firstName ?? ''} ${item?.user?.lastName ?? ''}`).trim()
                    return (
                      <tr key={item._id} data-i={i}>
                        {(!showColumns || showColumns?.includes(0)) && (
                          <td>
                            {/*
                              <span className="fw-normal">{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</span>
                              */}
                            <div>
                              <span className="fw-normal">{moment(item.createdAt).format('DD MMM YY')}</span>
                            </div>
                            <div>
                              <span className="small text-muted fw-normal">{moment(item.createdAt).format('HH:mm')}</span>
                            </div>
                          </td>
                        )}

                        {(!showColumns || showColumns?.includes(1)) && (
                          <td>
                            <span className="fw-normal">{item.description}</span>
                            {item?.review && (
                              <Card.Link as={Link} to={Routes.Review.path.replace(':_id', item?.review?._id)} className="d-flex align-items-center">
                                <span className="fw-bold text-blue">{item?.review?.product?.productTitle ?? 'No product name'}</span>
                              </Card.Link>
                            )}
                            {item.earningRecords.map(earningRecord => (
                              <EarningRecordItem item={earningRecord} />
                            ))}
                            {item.reversal && (
                              <>
                                {/* repeat again here */}
                                {!item.reversal?.transactionType && (
                                  <>
                                    <div>
                                      <span className="fw-normal small">Reverted: {item.reversal?.description ?? 'No description'}</span>
                                    </div>
                                    <div>
                                      <span className="fw-normal small">Reverted id: {item.reversal?._id}</span>
                                    </div>
                                  </>
                                )}
                                {item.reversal?.review && (
                                  <Card.Link as={Link} to={Routes.Review.path.replace(':_id', item.reversal?.review?._id)} className="d-flex align-items-center">
                                    <span className="fw-bold text-blue">{item.reversal?.review?.product?.productTitle ?? 'No product name'}</span>
                                  </Card.Link>
                                )}
                                {item.reversal.earningRecords.map(earningRecord => (
                                  <EarningRecordItem item={earningRecord} />
                                ))}
                                {/* repeat again here */}
                              </>
                            )}
                          </td>
                        )}

                        {(!showColumns || showColumns?.includes(2)) && (
                          <td><span className="fw-normal">{item.transactionPoints}</span></td>
                        )}

                        {(!showColumns || showColumns?.includes(3)) && (
                          <td><span className="fw-normal">{item.pointsBalance}</span></td>
                        )}

                        {(!showColumns || showColumns?.includes(4)) && (
                          <td>
                              <Card.Link as={Link} to={Routes.Member.path.replace(':_id',item?.user?._id)} className="d-flex align-items-center">
                                <span className="fw-bold text-blue">{userName || 'No name'}</span>
                              </Card.Link>
                          </td>
                        )}
                      </tr>
                    );
                  })
                  .value()
              }
              {count === 0 && (
                <tr>
                  <td colSpan={showColumns ? showColumns.length : 5}><span className="fw-normal">No entries to show</span></td>
                </tr>
              )}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            {showPagination !== false && (
              <Nav>
                <PaginationTool query={query} setQuery={setQuery} collection={itemsListRes} />
              </Nav>
            )}
            <small className="fw-bold">
              Showing <b>{count}</b> out of <b>{countTotal}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
}

export default Component;