import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

//portal pages
import GeneralLanding from "./GeneralLanding";

// pages
import Users from "./users/Page";
import User from "./user/review/Page";
import AddUser from "./user/add/Page";
import EditUser from "./user/edit/Page";

import MyProfile from "./myProfile/review/Page";
import EditMyProfile from "./myProfile/edit/Page";

import Member from "./member/review/Page";
import AddMember from "./member/add/Page";
import EditMember from "./member/edit/Page"
import Members from './members/Page';
import AddMemberPoints from "./member-points/add/Page"

import Partner from './partner/review/Page';
import AddPartner from "./partner/add/Page";
import EditPartner from "./partner/edit/Page"
import Partners from "./partners/Page";

import PartnerShare from './partnerShare/review/Page';
import AddPartnerShare from "./partnerShare/add/Page";
import EditPartnerShare from "./partnerShare/edit/Page"
import PartnerShares from "./partnerShares/Page";

import ApiKey from './apiKey/review/Page';
import AddApiKey from "./apiKey/add/Page";
import EditApiKey from "./apiKey/edit/Page"
import ApiKeys from "./apiKeys/Page";

import EarningSettings from "./earning-settings/Page"
import EarningSetting from "./earning-setting/review/Page"
import AddEarningSetting from "./earning-setting/add/Page";
import EditEarningSetting from "./earning-setting/edit/Page"

import AddEarningSettingsAction from './earning-setting-additional-action/add/Page'
import EditEarningSettingsAction from './earning-setting-additional-action/edit/Page'

import Dashboard from "./dashboard/Dashboard";
import DashboardOverview from "./dashboard/DashboardOverview";
import Settings from "./Settings";

import Signin from "./Signin";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";
import Lock from "./Lock";
import NotFoundPage from "./NotFound";
import ServerError from "./ServerError";
import ForbiddenPage from "./Forbidden";

import Brands from "./brands/Page";
import Products from "./products/Page";

import Reviews from "./reviews/Page"
import Review from './review/review/Page';

import Transactions from "./transactions/Page";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer />
        </main>
      </>
    )}
    />
  );
};

const Component = () => (
  <Switch>
    
    <RouteWithLoader exact path={Routes.GeneralLanding.path} component={GeneralLanding} />

    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.VerifyEmail.path} component={VerifyEmail} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    <RouteWithLoader exact path={Routes.Forbidden.path} component={ForbiddenPage} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.Dashboard.path} component={Dashboard} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />

    <RouteWithSidebar exact path={Routes.AddUser.path} component={AddUser} />
    <RouteWithSidebar exact path={Routes.EditUser.path} component={EditUser} />
    <RouteWithSidebar exact path={Routes.User.path} component={User} /> 
    <RouteWithSidebar exact path={Routes.Users.path} component={Users} /> 

    <RouteWithSidebar exact path={Routes.MyProfile.path} component={MyProfile} /> 
    <RouteWithSidebar exact path={Routes.EditMyProfile.path} component={EditMyProfile} /> 

    <RouteWithSidebar exact path={Routes.AddMember.path} component={AddMember} />
    <RouteWithSidebar exact path={Routes.EditMember.path} component={EditMember} />
    <RouteWithSidebar exact path={Routes.Member.path} component={Member} /> 
    <RouteWithSidebar exact path={Routes.Members.path} component={Members} />
    <RouteWithSidebar exact path={Routes.AddMemberPoints.path} component={AddMemberPoints} />

    <RouteWithSidebar exact path={Routes.AddPartner.path} component={AddPartner} />
    <RouteWithSidebar exact path={Routes.EditPartner.path} component={EditPartner} />
    <RouteWithSidebar exact path={Routes.Partner.path} component={Partner} /> 
    <RouteWithSidebar exact path={Routes.Partners.path} component={Partners} />

    <RouteWithSidebar exact path={Routes.AddPartnerShare.path} component={AddPartnerShare} />
    <RouteWithSidebar exact path={Routes.EditPartnerShare.path} component={EditPartnerShare} />
    <RouteWithSidebar exact path={Routes.PartnerShare.path} component={PartnerShare} /> 
    <RouteWithSidebar exact path={Routes.PartnerShares.path} component={PartnerShares} />

    <RouteWithSidebar exact path={Routes.AddApiKey.path} component={AddApiKey} />
    <RouteWithSidebar exact path={Routes.EditApiKey.path} component={EditApiKey} />
    <RouteWithSidebar exact path={Routes.ApiKey.path} component={ApiKey} /> 
    <RouteWithSidebar exact path={Routes.ApiKeys.path} component={ApiKeys} />

    <RouteWithSidebar exact path={Routes.AddEarningSetting.path} component={AddEarningSetting} />
    <RouteWithSidebar exact path={Routes.EditEarningSetting.path} component={EditEarningSetting} /> 
    <RouteWithSidebar exact path={Routes.EarningSetting.path} component={EarningSetting} />
    <RouteWithSidebar exact path={Routes.EarningSettings.path} component={EarningSettings} />

    <RouteWithSidebar exact path={Routes.AddEarningSettingsAction.path} component={AddEarningSettingsAction} />
    <RouteWithSidebar exact path={Routes.EditEarningSettingsAction.path} component={EditEarningSettingsAction} /> 


    <RouteWithSidebar exact path={Routes.Brands.path} component={Brands} /> 
    <RouteWithSidebar exact path={Routes.Products.path} component={Products} /> 

    {/*
    <RouteWithSidebar exact path={Routes.AddReview.path} component={AddReview} />
    <RouteWithSidebar exact path={Routes.EditReview.path} component={EditReview} />
    */}
    <RouteWithSidebar exact path={Routes.Review.path} component={Review} /> 
    <RouteWithSidebar exact path={Routes.Reviews.path} component={Reviews} />
    

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);

export default Component;
