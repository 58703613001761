import React from "react";
import { Col, Row, Card } from 'react-bootstrap';

import EditButton from './EditButton'
import DeleteButton from './DeleteButton'
import { Link } from 'react-router-dom';
import { Routes } from "../../../routes";

const Component = (props) => {
  const { item } = props;
  
  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Card.Header className="px-0">
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <h6>{item.name || 'No name'}</h6>
              </Col>
              <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center">
                <div className="ms-0 ms-md-1 mt-4 mt-md-0">
                  <EditButton item={item} />
                </div>
                <div className="ms-1 mt-4 mt-md-0">
                  <DeleteButton item={item} />
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Row>
            <Col xs={12} className="py-2">
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Type</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  <span className="fw-normal">{item.apiKeyType}</span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Role</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  <span className="fw-normal">{item.role.roleName}</span>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Owner</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  {['PartnerKey'].includes(item.apiKeyType) && (
                    <Card.Link as={Link} to={Routes.Partner.path.replace(':_id',item.owner?._id)}>
                      <span className="fw-bold text-blue">{item.owner?.partner_title}</span>
                    </Card.Link>
                  )}
                  {['UserKey'].includes(item.apiKeyType) && (
                    <Card.Link as={Link} to={Routes.User.path.replace(':_id',item.owner?._id)}>
                      <span className="fw-bold text-blue">
                        {item.owner?.displayName || (`${item.owner?.firstName ?? ''} ${item.owner?.lastName ?? ''}`).trim()}
                      </span>
                    </Card.Link>
                  )}
                  {!['PartnerKey', 'UserKey'].includes(item.apiKeyType) && (
                    <span className="fw-bold">{item.owner?._id}</span>
                  )}
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Api Key</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  <pre className="fw-normal small">{item.apiKey}</pre>
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12} md={4} className="py-1">
                  <span className="fw-bold">Api Secret</span>
                </Col>
                <Col xs={12} md={8} className="py-1">
                  <pre className="fw-normal small">{item.apiSecret}</pre>
                </Col>
              </Row>
              {item.corsWhitelistUrl.length > 0 && (
                <Row className="py-1">
                  <Col xs={12} md={4} className="py-1">
                    <span className="fw-bold">CORS Whitelist Urls</span>
                  </Col>
                  <Col xs={12} md={8} className="py-1">
                    {item.corsWhitelistUrl.map((url, i) => (
                      <div key={i} className="fw-normal text-blue text-decoration-none">
                        <a href={`${url}`} rel="noopener noreferrer">
                          {url}
                        </a>
                      </div>
                    ))}
                  </Col>
                </Row>
              )}
              {item.whitelistIp.length > 0 && (
                <Row className="py-1">
                  <Col xs={12} md={4} className="py-1">
                    <span className="fw-bold">Whitelist IPs</span>
                  </Col>
                  <Col xs={12} md={8} className="py-1">
                    {item.whitelistIp.map((ip, i) => (
                      <div key={i} className="fw-normal">
                        {ip}
                      </div>
                    ))}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Component;
