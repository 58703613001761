import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"
import { Redirect } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { partnerActions } from '../../../store/partners';

import EmailInput from '../../../components/ReactBootstrapFromElements/EmailInput'
import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import ReactSelectAsync from '../../../components/ReactBootstrapFromElements/ReactSelectAsyncInput'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  _id: yup.string(),
  memberId: yup.string(),
  firstName: yup.string().min(2),
  lastName: yup.string().min(2),
  displayName: yup.string().min(2),
  email: yup.string().email().required(),
  partner: yup.string().required('Partner can not be empty'),
  address: yup.object({
    street: yup.string(),
    suburb: yup.string(),
    state: yup.string(),
    postCode: yup.string(),
    country: yup.string(),
    phone: yup.string()
  }),
  mobile: yup.string()
})
.noUnknown(true);

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;
  
  const { register, formState: { errors }, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  const isSuccess = _.get(submitResult, 'status') === 'success'

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="firstName" title="First Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="lastName" title="Last Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="displayName" title="Display Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <EmailInput disabled={readonly} autoFocus={false} name="email" title="Email" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAsync 
            disabled={readonly}
            autoFocus={false} 
            name="partner"
            title="Partner" 
            className="mb-4"
            control={control} 
            errors={errors}

            Actions={partnerActions}
            actionName="partnersList"
            queryParams={{limit: 100}}
            itemsPath="partners_data.partnersList"
            fieldName="partner_title"
            placeholder="Select partner"
            isClearable={true}
            selectedValue={item.partner}
          />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="address.street" title="Street" placeholder="Street number and street name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="address.suburb" title="Suburb" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="address.state" title="State" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="address.postCode" title="Post Code" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="address.country" title="Country" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="address.phone" title="Phone" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="mobile" title="Mobile" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="memberId" title="Member ID" className="mb-4" register={register} errors={errors} />
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
