import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getWithExpiry } from '../../helpers/localStorage'
import { earningSettingActions as Actions } from '../../store/earningSettings';
import { useQuery } from "../../helpers/useQuery"; 
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

import { Button, ButtonGroup, Row, Col, InputGroup, Form, Dropdown, Card, Table, Nav } from "react-bootstrap";
import PaginationTool from '../../components/multitools/PaginationTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';

import Alerts from '../../components/multitools/Alerts'
import Preloader from "../../components/Preloader";


const Component = (props) => {
  const { query, setQuery, history } = useQuery();
  const name = 'earningSettingsList';
  const stateName = 'earning_settings_data';

  const dispatch = useDispatch();
  const istemsList = payload => dispatch(Actions[name](payload))
  const itemsListRes = useSelector(state => state[stateName][name])
  const status = itemsListRes?.status ?? ''
  const error = itemsListRes?.data?.error ?? 'Error'
  const items = itemsListRes?.data?.items ?? []
  const count = itemsListRes?.data?.meta?.count ?? 0
  const countTotal = itemsListRes?.data?.meta?.countTotal ?? 0

  const limit = query.get('limit') ?? '10'
  const offset = query.get('offset') ?? '0'
  const [search, setSearch] = useState(query.get('search') ?? '')

  useEffect(() => {
      const accessToken = getWithExpiry('accessToken');
      if (accessToken) {
          const payload = {
            data: {
              limit,
              offset,
              search
            },
            token: accessToken,
          }
          istemsList(payload);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    setQuery({ search, offset: 0 })
  }

  // is loading
  if (['', 'initial', 'loading'].includes(status)) {
    return <Preloader show={true} />
  }

  if (['fail', 'error'].includes(status)) {
    return (
      <div className="mt-3 mb-3">
        <Alerts alerts={error} />
      </div>
    )
  }

  return (
    <>
      <div className="table-settings mb-2 mb-lg-4">
        <Row className="justify-content-between align-items-center">
            <Col xs={12} lg={4} className="d-flex py-2 pe-lg-3">
              <Form onSubmit={handleSearchSubmit} noValidate={true} className="w-100">
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Search by title" value={search} onChange={ event => {
                      setSearch(event.target.value);
                    }} />
                    <Button variant="outline-primary" type="submit">
                      Search
                    </Button>
                </InputGroup>
              </Form>
            </Col>

            <Col xs={12} lg={4} className="text-end py-2">
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm icon-gray">
                            <FontAwesomeIcon icon={faSlidersH} />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                        <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                        <Dropdown.Item className="d-flex fw-bold" onClick={() => setQuery({ limit: 10, offset: 0 })}>
                          10 
                          {limit === '10' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 20, offset: 0 })}>
                          20 
                          {limit === '20' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 50, offset: 0 })}>
                          50 
                          {limit === '50' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 100, offset: 0 })}>
                          100 
                          {limit === '100' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
            <Table hover className="user-table align-items-center">
                <thead>
                    <tr>
                        <th className="border-bottom">Earning method</th>
                        <th className="border-bottom">Type</th>
                        <th className="border-bottom">Status</th>
                        <th className="border-bottom">Base Points</th>
                        <th className="border-bottom">Max Points</th>
                        <th className="border-bottom">Achieved</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    _(items)
                    .map(function(item, i) {
                      return (
                        <tr key={item._id} data-i={i}>
                            <td>
                              <Card.Link as={Link} to={Routes.EarningSetting.path.replace(':_id',item._id)}>
                                <span className="fw-bold text-blue">{item.title}</span>
                              </Card.Link>
                            </td>
                            <td><span className="fw-normal">{item.earningSettingType}</span></td>
                            <td><span className={`fw-normal ${(item.status === 'Active') && 'text-success'}`}>{item.status}</span></td>
                            <td><span className="fw-normal">{item.basePoints}</span></td>
                            <td><span className="fw-normal">{item.maxPoints}</span></td>
                            <td><span className="fw-normal">{item.achievedCount || 0}</span></td>
                        </tr>
                      );
                    })
                    .value()
                  }
                  {count === 0 && (
                    <tr>
                      <td colSpan="6"><span className="fw-normal">No entries to show</span></td>
                    </tr>
                  )}
                </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <PaginationTool query={query} setQuery={setQuery} collection={itemsListRes} />
            </Nav>
            <small className="fw-bold">
                Showing <b>{count}</b> out of <b>{countTotal}</b> entries
            </small>
            </Card.Footer>
        </Card.Body>
    </Card>
    </>
  );
}

export default Component;