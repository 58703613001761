import { combineReducers } from "redux";
import _ from "lodash";

import * as transactionsList from "./transactionsList";
import * as getTransaction from "./getTransaction";
import * as createTransaction from "./createTransaction";

const slices = {
    transactionsList,
    createTransaction,
    getTransaction,
};

const transactionTypes = _.mapValues(slices, (s) => s.slice.types );
const transactionActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const transactionSagas = _(slices).values().map('slice.sagas').flatten().value()
const transactionSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    transactionTypes,
    transactionActions,
    transactionSagas,
    transactionSelectors,
};

export default combineReducers(reducers);