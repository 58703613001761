import React from "react";
import { Col, Row, Card, Image, Badge } from 'react-bootstrap';
import moment from 'moment-timezone'
import _ from 'lodash'
/*
import EditButton from './EditButton'
import DeleteButton from './DeleteButton'
import { Link } from 'react-router-dom';
import { Routes } from "../../../routes";
*/

import userAvatar from "../../../assets/img/avatar/user-avatar.jpg"
import { Link } from 'react-router-dom';
import { Routes } from "../../../routes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';


const Component = (props) => {
  const { item } = props;
  const userName = (`${item?.member?.firstName ?? ''} ${item?.member?.lastName ?? ''}`).trim()

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <Card.Header className="px-0">
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <h6>Score: {item?.reviewScore?.$numberDecimal}</h6>
              </Col>
              <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center">
                {/*
                <div className="ms-0 ms-md-1 mt-4 mt-md-0">
                  <EditButton item={item} />
                </div>
                <div className="ms-1 mt-4 mt-md-0">
                  <DeleteButton item={item} />
                </div>
                */}
              </Col>
            </Row>
          </Card.Header>
          <Row>
            <Col xs={12} className="py-2">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <Card.Link as={Link} to={Routes.Member.path.replace(':_id',item?.member?._id)} className="d-flex align-items-center justify-content-start">
                    <Image src={item?.member?.image ?? userAvatar} className="user-avatar rounded-circle me-3" />
                    <div className="d-block">
                      <span className="fw-bold">{item?.member?.displayName || userName || 'No name'}</span>
                    </div>
                  </Card.Link>
                </div>
                <div>
                  <div>
                    <small><span className="fw-normal fst-italic">{moment(item.reviewDate).format('DD.MM.YYYY HH:mm')}</span></small>
                  </div>
                </div>
              </div>

              <div className="py-1">
                <span className="fw-normal">
                  {item?.product?.productTmbUrl && (
                    <a href={item?.product?.productTmbUrl} target="_blank" rel="noopener noreferrer" className="fw-normal">
                      <small className="fw-normal fst-italic">{item?.product?.productTitle}</small>
                      <span className="icon icon-sm icon-gray px-2">
                        <FontAwesomeIcon icon={faExternalLinkAlt} className="pl-1" style={{fontSize: '0.65rem'}} />
                      </span>
                    </a>
                  )}
                </span>
              </div>
              
              <div className="py-1"><span className="fw-bold">{item.reviewTitle || 'No title'}</span></div>
              <div className="py-1">
                <span className="fw-normal">{item.reviewComment}</span>
              </div>
              <div className="py-1">
                <Badge pill bg={item.reviewStatus === "Published" ? "secondary" : "warning"} text="primary" className="badge-md notification-count me-2">{item.reviewStatus.toLowerCase()}</Badge>
              </div>


              <div className="py-3">
                <div>
                  <span className="fw-normal">{(item?.reviewImages || []).length} images</span>
                </div>
                {  _(item?.reviewImages || [])
                    .map(function(imgItemUrl, i) {
                      return (
                        <a key={i} href={imgItemUrl} target="_blank" rel="noopener noreferrer" className="fw-normal">
                          <Image src={imgItemUrl} width="60" thumbnail />  
                        </a>
                      )
                    })
                    .value()
                }
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Component;
