
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faCog, faFileAlt, faCashRegister, faUser, faUsers, faBriefcase, faArchive, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import TellmeBabyMiniLogoPink from "../assets/img/tellme-brand-logos/mini_pink.svg";
import MyProfileMobile from './MyProfileMoblile'
import CheckPermissions from './multitools/CheckPermissions'

const Component = (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    const bkgColor = '#262b40'; // on :hover #2e3650

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey} style={{backgroundColor: bkgColor}}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center" style={{backgroundColor: bkgColor}}>
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.GeneralLanding.path}>
          <Image src={TellmeBabyMiniLogoPink} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <MyProfileMobile onCollapse={onCollapse} />

            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Tell Me Baby" link={Routes.GeneralLanding.path} image={TellmeBabyMiniLogoPink} />

              <NavItem title="Dashboard" link={Routes.Dashboard.path} icon={faChartPie} />

              <CheckPermissions 
                requirements={{
                    componentId: 'NavItemUsers',
                    auth: true,
                    endpoints: [ { method: 'GET', path: '/users' } ]
                }}
                childrenIfNoPermission={null}
              >
                <NavItem title="Users" icon={faUser} link={Routes.Users.path} />
              </CheckPermissions>

              <CheckPermissions 
                requirements={{
                    componentId: 'NavItemMembers',
                    auth: true,
                    endpoints: [ { method: 'GET', path: '/members' } ]
                }}
                childrenIfNoPermission={null}
              >
                <NavItem title="Members" icon={faUsers} link={Routes.Members.path} />
              </CheckPermissions>

              <CheckPermissions 
                requirements={{
                    componentId: 'NavItemBrands',
                    auth: true,
                    endpoints: [
                      { method: 'GET', path: '/brands' },
                      { method: 'GET', path: '/partners' }
                    ]
                }}
                childrenIfNoPermission={null}
              >
                <NavItem title="Brands" icon={faBriefcase} link={Routes.Brands.path} />
              </CheckPermissions>

              <CheckPermissions 
                requirements={{
                    componentId: 'NavItemProducts',
                    auth: true,
                    endpoints: [ 
                      { method: 'GET', path: '/products' },
                      { method: 'GET', path: '/partners' }
                    ]
                }}
                childrenIfNoPermission={null}
              >
                <NavItem title="Products" icon={faArchive} link={Routes.Products.path} />
              </CheckPermissions>

              <CheckPermissions 
                requirements={{
                    componentId: 'NavItemReviews',
                    auth: true,
                    endpoints: [ 
                      { method: 'GET', path: '/reviews' },
                      { method: 'GET', path: '/partners' }
                    ]
                }}
                childrenIfNoPermission={null}
              >
                <NavItem title="Reviews" icon={faCommentDots} link={Routes.Reviews.path} />
              </CheckPermissions>

              <CheckPermissions 
                requirements={{
                    componentId: 'NavItemTransactions',
                    auth: true,
                    endpoints: [ { method: 'GET', path: '/transactions' } ]
                }}
                childrenIfNoPermission={null}
              >
                <NavItem title="Transactions" icon={faCashRegister} link={Routes.Transactions.path} />
              </CheckPermissions>
              
              <CollapsableNavItem eventKey="settings/" title="Program settings" icon={faCog}>
                <CheckPermissions 
                  requirements={{
                      componentId: 'NavItemPartners',
                      auth: true,
                      endpoints: [ 
                        { method: 'GET', path: '/partners' }
                      ]
                  }}
                  childrenIfNoPermission={null}
                >
                  <NavItem title="Partners" link={Routes.Partners.path} />
                </CheckPermissions>

                <CheckPermissions 
                  requirements={{
                      componentId: 'NavItemPartnerShares',
                      auth: true,
                      endpoints: [ 
                        { method: 'GET', path: '/partner-shares' }
                      ]
                  }}
                  childrenIfNoPermission={null}
                >
                  <NavItem title="Partner Shares" link={Routes.PartnerShares.path} />
                </CheckPermissions>

                <CheckPermissions 
                  requirements={{
                      componentId: 'NavItemApiKeys',
                      auth: true,
                      endpoints: [ 
                        { method: 'GET', path: '/api-keys' }
                      ]
                  }}
                  childrenIfNoPermission={null}
                >
                  <NavItem title="Api Keys" link={Routes.ApiKeys.path} />
                </CheckPermissions>

                <CheckPermissions 
                  requirements={{
                      componentId: 'NavItemEarningSettings',
                      auth: true,
                      endpoints: [ 
                        { method: 'GET', path: '/earning-settings' }
                      ]
                  }}
                  childrenIfNoPermission={null}
                >
                  <NavItem title="Points" link={Routes.EarningSettings.path} />
                </CheckPermissions>
              </CollapsableNavItem>
              
              
              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="examples/" title="Design Samples" icon={faFileAlt}>
                <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
                <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
              </CollapsableNavItem>

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Component;
