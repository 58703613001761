import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"
import { Redirect } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { settingsActions } from '../../../store/settings';
import { roleActions } from '../../../store/roles';
import { partnerActions } from '../../../store/partners';
import { userActions } from '../../../store/users';

import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import ReactSelectAsync from '../../../components/ReactBootstrapFromElements/ReactSelectAsyncInput'
import ReactSelectAutocompleteInput from '../../../components/ReactBootstrapFromElements/ReactSelectAutocompleteInput'
import ReactSelectMultiCreateInput from '../../../components/ReactBootstrapFromElements/ReactSelectMultiCreateInput'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  _id: yup.string(),
  name: yup.string().min(2),
  owner: yup.string().required(),
  role: yup.string().required(),
  apiKeyType: yup.string().oneOf(['UserKey', 'PartnerKey']),
  corsWhitelistUrl: yup.array().of(yup.string().url()),
  whitelistIp: yup.array().of(yup.string().matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: 'Invalid IP address',
      excludeEmptyString: true
    }).test('ip', 'Invalid IP address', value => {
      return value === undefined || value.trim() === ''
        ? true
        : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
    })
  )
})
.noUnknown(true);

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;

  // simple _id required for <ReactSelectAsync />
  if (item.role?._id) {
    item.role = item.role._id
  }
  let ownerDefaultValue
  if (item.owner?._id) {
    const userName = item.owner?.displayName || (`${item.owner?.firstName ?? ''} ${item.owner?.lastName ?? ''}`).trim() || item.owner?.email
    ownerDefaultValue = { value: item.owner._id, label: item.owner?.partner_title || userName || 'No name' }
    item.owner = item.owner._id
  }
  
  const { register, watch, setValue, formState: { errors }, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  const isSuccess = _.get(submitResult, 'status') === 'success'

  const apiKeyType = watch('apiKeyType')
  const isListDisabled = (listCode) => {
    if (readonly) {
      return true
    }

    // will not be accepted by PUT endpoint, so disabling the inputs
    if (item._id && ['keyType', 'role', 'owner'].includes(listCode)) {
      return true
    }

    if (!apiKeyType) {
      if (listCode === 'keyType') {
        return false
      }
      return true
    }
  }

  // console.log('apiKey formState:', getValues())
  // console.log('err', errors);

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="name" title="Name" className="mb-4" placeholder="Any descriptive name" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAsync
            Actions={settingsActions}
            actionName="apiKeyTypesList"
            queryParams={{}}
            itemsPath="settings_data.apiKeyTypesList"
            fieldId="setting.key"
            fieldName="setting.value"
            placeholder="Type"
            isClearable={true}
            selectedValue={item.apiKeyType}

            disabled={isListDisabled('keyType')}
            autoFocus={false} 
            name="apiKeyType"
            title="Type" 
            className="mb-4"
            control={control} 
            errors={errors}

            onChange={(val) => {
              setValue('owner', '')
              setValue('role', '')
              setValue('whitelistIp', [])
              setValue('corsWhitelistUrl', [])
            }}
          />
        </Col>

        {apiKeyType === 'PartnerKey' && (
          <Col md={6} className="mb-3">
            <ReactSelectAsync 
              disabled={isListDisabled('role')}
              autoFocus={false} 
              name="role"
              title="Role" 
              className="mb-4"
              control={control} 
              errors={errors}

              Actions={roleActions}
              actionName="rolesList"
              queryParams={{roleOwnerTypes: 'Partner'}}
              itemsPath="roles_data.rolesList"
              fieldName="roleName"
              placeholder="Select partner role"
              isClearable={true}
              selectedValue={item.role}

              onChange={(val) => {
                setValue('whitelistIp', [])
                setValue('corsWhitelistUrl', [])
              }}
            />
          </Col>
        )}

        {apiKeyType === 'UserKey' && (
          <Col md={6} className="mb-3">
            <ReactSelectAsync 
              disabled={isListDisabled('role')}
              autoFocus={false} 
              name="role"
              title="Role" 
              className="mb-4"
              control={control} 
              errors={errors}

              Actions={roleActions}
              actionName="rolesList"
              queryParams={{roleOwnerTypes: 'User'}}
              itemsPath="roles_data.rolesList"
              fieldName="roleName"
              placeholder="Select user role"
              isClearable={true}
              selectedValue={item.role}

              onChange={(val) => {
                setValue('whitelistIp', [])
                setValue('corsWhitelistUrl', [])
              }}
            />
          </Col>
        )}

        {apiKeyType === 'PartnerKey' && (
          <Col md={6} className="mb-3">
            <ReactSelectAutocompleteInput 
              disabled={isListDisabled('owner')}
              autoFocus={false} 
              name="owner"
              title="Owner" 
              className="mb-4"
              control={control} 
              errors={errors}

              Actions={partnerActions}
              actionName="partnersList"
              queryParams={{limit: 10}}
              itemsPath="partners_data.partnersList"
              fieldName="partner_title"
              placeholder="Find partner by title"
              isClearable={true}
              selectedValue={item.owner}
              defaultValue={ownerDefaultValue}
            />
          </Col>
        )}

        {apiKeyType === 'UserKey' && (
          <Col md={6} className="mb-3">
            <ReactSelectAutocompleteInput 
              disabled={isListDisabled('owner')}
              autoFocus={false} 
              name="owner"
              title="Owner" 
              className="mb-4"
              control={control} 
              errors={errors}

              Actions={userActions}
              actionName="usersList"
              queryParams={{limit: 10}}
              itemsPath="users_data.usersList"
              fieldName="email"
              placeholder="Find user by email"
              isClearable={true}
              selectedValue={item.owner}
              defaultValue={ownerDefaultValue}
            />
          </Col>
        )}

        <Col md={6} className="mb-3">
          <ReactSelectMultiCreateInput
            disabled={isListDisabled('cors')}
            autoFocus={false} 
            name="corsWhitelistUrl"
            title="CORS Whitelist Urls" 
            className="mb-4"
            control={control} 
            errors={errors}
            placeholder=""
          />
        </Col>
        
        <Col md={6} className="mb-3">
          <ReactSelectMultiCreateInput
            disabled={isListDisabled('ip')}
            autoFocus={false} 
            name="whitelistIp"
            title="Whitelist IPs" 
            className="mb-4"
            control={control} 
            errors={errors}
            placeholder=""
          />
        </Col>

      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
