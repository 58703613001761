import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"
import { Redirect } from 'react-router-dom'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { partnerActions } from '../../../store/partners';

import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import ReactSelectAsync from '../../../components/ReactBootstrapFromElements/ReactSelectAsyncInput'
import Alerts from '../../../components/multitools/Alerts'
import ConditionBuilder from '../../../components/ReactBootstrapFromElements/ConditionBuilder'
import { fields } from '../../earning-setting/_components/queryBuilderFields'

const schema = yup.object().shape({
  _id: yup.string(),
  earningSetting: yup.string().required('earningSetting can not be empty'),
  partner: yup.string().required('Partner can not be empty'),
  title: yup.string().required(),
  points: yup.number().required(),
  condition: yup.object(),
  conditionTitle: yup.string()
})
.noUnknown(true);

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;

  const { register, formState: { errors }, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  const isSuccess = _.get(submitResult, 'status') === 'success'

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="title" title="Title" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="points" title="Points" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <ReactSelectAsync 
            disabled={readonly}
            autoFocus={false} 
            name="partner"
            title="Partner" 
            className="mb-4"
            control={control} 
            errors={errors}

            Actions={partnerActions}
            actionName="partnersList"
            queryParams={{limit: 100}}
            itemsPath="partners_data.partnersList"
            fieldName="partner_title"
            placeholder="Select partner"
            isClearable={true}
            selectedValue={item.partner}
          />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="conditionTitle" title="Activity label" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={12} className="mb-3">
          <ConditionBuilder
            disabled={readonly}
            autoFocus={false}
            name="condition"
            fields={fields}
            title="Condition builder"
            className="mb-4"
            control={control} 
            errors={errors}
          />
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
