import { combineReducers } from "redux";
import _ from "lodash";

import * as partnersList from "./partnersList";
import * as getPartner from "./getPartner";
import * as createPartner from "./createPartner";
import * as editPartner from "./editPartner";

const slices = {
    partnersList,
    createPartner,
    getPartner,
    editPartner,
};

const partnerTypes = _.mapValues(slices, (s) => s.slice.types );
const partnerActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const partnerSagas = _(slices).values().map('slice.sagas').flatten().value()
const partnerSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    partnerTypes,
    partnerActions,
    partnerSagas,
    partnerSelectors,
};

export default combineReducers(reducers);