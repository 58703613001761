import { combineReducers } from "redux";
import _ from "lodash";

import * as productsList from "./productsList";

const slices = {
    productsList,
};

const productTypes = _.mapValues(slices, (s) => s.slice.types );
const productActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const productSagas = _(slices).values().map('slice.sagas').flatten().value()
const productSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    productTypes,
    productActions,
    productSagas,
    productSelectors,
};

export default combineReducers(reducers);