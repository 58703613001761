import React from "react";

const Component = (params) => {
    const { item } = params
    return (
        <div key={item._id} className="small text-muted fw-normal">
          {/*
          <div>
            {`${item?.earningSetting?.title} ${item.achievedTotalPoints}`}
          </div>
          */}
          <div>
            {`${item?.earningSetting?.baseConditionTitle} - ${item?.achievedBasePoints} points`}          
          </div>
          <div>
            {item.additionalPoints.map(additionalPointsItem => (
              <div key={additionalPointsItem._id}>
                {`${additionalPointsItem?.earningSettingAction?.conditionTitle} - ${additionalPointsItem.achievedAdditionalPoints} points`}
              </div>
            ))}          
          </div>
          
        </div>
    );
};

export default Component;
