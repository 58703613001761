import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import NumInput from '../../../components/ReactBootstrapFromElements/NumInput'
import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  _id: yup.string(),
  transactionPoints: yup.number().typeError('specify a number').required().integer(),
  description: yup.string().required(),
  user: yup.string().required('User can not be empty'),
  partner: yup.string().required('Partner can not be empty')
})
.noUnknown(true);

const Component = (props) => {
  const { readonly, item, onSubmit, submitResult } = props;
  
  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <NumInput disabled={readonly} autoFocus={true} name="transactionPoints" title="Manual points adjustment" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="description" title="Reason for adjustment" className="mb-4" register={register} errors={errors} />
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
