import { combineReducers } from "redux";
import _ from "lodash";

import * as brandsList from "./brandsList";

const slices = {
    brandsList,
};

const brandTypes = _.mapValues(slices, (s) => s.slice.types );
const brandActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const brandSagas = _(slices).values().map('slice.sagas').flatten().value()
const brandSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    brandTypes,
    brandActions,
    brandSagas,
    brandSelectors,
};

export default combineReducers(reducers);