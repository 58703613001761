import React from "react";
import { Col, Row, Button,  Form } from 'react-bootstrap';
import _ from "lodash"
import { Redirect } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { roleActions } from '../../../store/roles';

import EmailInput from '../../../components/ReactBootstrapFromElements/EmailInput'
import TextInput from '../../../components/ReactBootstrapFromElements/TextInput'
import ReactSelectAsync from '../../../components/multitools/ReactSelectAsync'
import Alerts from '../../../components/multitools/Alerts'

const schema = yup.object().shape({
  firstName: yup.string().min(2),
  lastName: yup.string().min(2),
  email: yup.string().email().required()
});

const Component = (props) => {
  const { readonly, item, onSubmit, redirectAfterTo, submitResult } = props;
  
  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: item,
  });

  const isSubmitting = _.get(submitResult, 'status') === 'loading'
  const isSuccess = _.get(submitResult, 'status') === 'success'

  if (isSuccess && redirectAfterTo) {
    return <Redirect to={redirectAfterTo} />
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <Row>
        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={true} name="firstName" title="First Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <TextInput disabled={readonly} autoFocus={false} name="lastName" title="Last Name" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <EmailInput disabled={readonly} autoFocus={false} name="email" title="Email" className="mb-4" register={register} errors={errors} />
        </Col>

        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <ReactSelectAsync
              Actions={roleActions}
              actionName="rolesList"
              queryParams={{'roleOwnerTypes[0]': 'User', 'roleOwnerTypes[1]': 'PartnerMember'}}
              itemsPath="roles_data.rolesList"
              fieldName="roleName"
              placeholder="Select role"
              isClearable={true}
              selectedValue={item.role}
              isDisabled={true}
              autoFocus={false} 
            />
          </Form.Group>
        </Col>
      </Row>

      <Alerts alerts={_.get(submitResult, 'data.error')} />

      <div className="mt-3">
        {!readonly && (
          <Button variant="primary" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Saving...' : 'Save'}</Button>
        )}

        {readonly && (
          <p className="mb-0">This form is readonly</p>
        )}
      </div>

    </Form>
  );
};

export default Component;
