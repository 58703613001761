import React, { useEffect } from "react";
import { Col, Row, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import _ from "lodash"

import { Routes } from "../../../routes";

import { getWithExpiry } from '../../../helpers/localStorage'
import { useDispatch, useSelector } from 'react-redux';
import { transactionActions as Actions } from '../../../store/transactions';

import Form from '../_components/Form'

const Component = (props) => {
  const { readonly, item } = props;
  
  const actionName = 'createTransaction';
  const stateName = 'transactions_data'
  const actionStateSelector = state => state[stateName][actionName];
  
  const dispatch = useDispatch();
  const formAction = payload => dispatch(Actions[actionName](payload));
  const actionReset = () => dispatch(Actions[`${actionName}Reset`]());
  const submitResult = useSelector(actionStateSelector);
  const itemId = _.get(submitResult, 'data.item._id');

  useEffect(() => {
    actionReset()
    return actionReset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = data => {
    const accessToken = getWithExpiry('accessToken')
    if (!accessToken) {
      document.location.reload()
    }
    const payload = {
      data,
      token: accessToken,
      id: data._id,
    }
    formAction(payload);
  }

  if (itemId) {
    return (
      <Redirect to={Routes.Member.path.replace(':_id', item.user)} />
    )
  }
  
  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Manual points adjustment</h5>
              <Form readonly={readonly} item={item} onSubmit={onSubmit} submitResult={submitResult} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Component;
