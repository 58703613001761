import React, { useState } from "react";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEye, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Routes } from "../../routes";

import CheckPermissions from '../../components/multitools/CheckPermissions'
import DeletionModal from '../../components/modals/DeletionModal'

import { apiKeyActions as Actions } from '../../store/apiKeys'

const Component = (props) => {
    const { item, redirectAfterTo } = props
    // this component requirements
    const [editComponentId] = useState(`EditApiKeyButton_${Date.now()}`);
    const editRequirements = {
        componentId: editComponentId,
        auth: true,
        endpoints: [
            { method: 'PUT', path: '/api-keys/:_id' },
        ]
    }

    const [deleteComponentId] = useState(`DeleteApiKeyButton_${Date.now()}`);
    const deleteRequirements = {
        componentId: deleteComponentId,
        auth: true,
        endpoints: [
            { method: 'DELETE', path: '/api-keys/:_id' },
        ]
    }

    const BtnRemove = (
      <><FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove</>
    )

    return (
      <>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={Routes.ApiKey.path.replace(':_id',item._id)} >
              <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
            </Dropdown.Item>
            <CheckPermissions requirements={editRequirements} childrenIfNoPermission={null} childrenIfLoading={null}>
              <Dropdown.Item as={Link} to={Routes.EditApiKey.path.replace(':_id',item._id)} >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
            </CheckPermissions>
            <CheckPermissions requirements={deleteRequirements} childrenIfNoPermission={null} childrenIfLoading={null}>
              <Dropdown.Item className="text-danger">
                <DeletionModal
                  Actions={Actions}
                  actionName="deleteApiKey"
                  stateName="api_keys_data"
                  item={item}
                  btn={BtnRemove}
                  modalHeading="Delete API Key"
                  modalBody="Are you sure?"
                  redirectAfterTo={redirectAfterTo}
                />
              </Dropdown.Item>
            </CheckPermissions>
          </Dropdown.Menu>
        </Dropdown>
      </> 
    );
};

export default Component;
