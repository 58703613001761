import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { transactionActions as Actions } from '../../store/transactions';
import CheckPermissions from '../../components/multitools/CheckPermissions'
import { getWithExpiry } from '../../helpers/localStorage'

import Alerts from '../../components/multitools/Alerts'
import Preloader from '../../components/Preloader'

import { StatsCounter } from "../../components/widgets/StatsCounter";
import { faCashRegister } from '@fortawesome/free-solid-svg-icons';


const Component = () => {
    // this component requirements
    const [componentId] = useState(`TransactionsCountWidget_${Date.now()}`);
    const requirements = {
        componentId,
        auth: true,
        endpoints: [
            { method: 'GET', path: '/transactions' },
        ]
    }

    const name = 'transactionsList';
    const stateName = 'transactions_data';

    const dispatch = useDispatch();
    const istemsList = payload => dispatch(Actions[name](payload))
    const itemsListRes = useSelector(state => state[stateName][name])
    const status = itemsListRes?.status ?? ''
    const error = itemsListRes?.data?.error ?? 'Error'
    const countTotal = itemsListRes?.data?.meta?.countTotal ?? 0

    useEffect(() => {
      const accessToken = getWithExpiry('accessToken');
      if (accessToken) {
          const payload = {
            data: {
              limit: '10',
              offset: '0',
              search: ''
            },
            token: accessToken,
          }
          istemsList(payload);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // is loading
    if (['', 'initial', 'loading'].includes(status)) {
        return <Preloader show={true} />
    }

    if (['fail', 'error'].includes(status)) {
        return (
        <div className="mt-3 mb-3">
            <Alerts alerts={error} />
        </div>
        )
    }

    return (
        <>
            <CheckPermissions requirements={requirements}>
                <StatsCounter
                    category="Transactions"
                    title={parseFloat(countTotal).toLocaleString('en')}
                    icon={faCashRegister}
                    iconColor="shape-secondary"
                />
            </CheckPermissions>
        </>
    );
};

export default Component;
