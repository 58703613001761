import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getWithExpiry } from '../../helpers/localStorage'
import { reviewActions as Actions } from '../../store/reviews';
import { partnerActions } from '../../store/partners';
import { useQuery } from "../../helpers/useQuery"; 
import _ from 'lodash';
import moment from 'moment';
import { Badge } from 'react-bootstrap';
/*
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
*/
import { Button, ButtonGroup, Row, Col, InputGroup, Form, Image, Dropdown, Card, Table, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSearch, faSlidersH, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import ReactSelectAsync from '../../components/multitools/ReactSelectAsync'

import Alerts from '../../components/multitools/Alerts'
import Preloader from "../../components/Preloader";
import PaginationTool from "../../components/multitools/PaginationTool"

import userAvatar from "../../assets/img/avatar/user-avatar.jpg"
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

const Component = (props) => {
  const { query, setQuery, history } = useQuery();
  const name = 'reviewsList';
  const stateName = 'reviews_data';

  const dispatch = useDispatch();
  const istemsList = payload => dispatch(Actions[name](payload))
  const itemsListRes = useSelector(state => state[stateName][name])
  const status = itemsListRes?.status ?? ''
  const error = itemsListRes?.data?.error ?? 'Error'
  const items = itemsListRes?.data?.items ?? []
  const count = itemsListRes?.data?.meta?.count ?? 0
  const countTotal = itemsListRes?.data?.meta?.countTotal ?? 0

  const limit = query.get('limit') ?? '10'
  const offset = query.get('offset') ?? '0'
  const [search, setSearch] = useState(query.get('search') ?? '')

  const partnerId = query.get('partnerId') ?? ''

  useEffect(() => {
      const accessToken = getWithExpiry('accessToken');
      if (accessToken) {
          const payload = {
            data: {
              limit,
              offset,
              search,
            },
            token: accessToken,
          }

          if (partnerId) {
            payload.data.partnerId = partnerId
          }

          istemsList(payload);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    setQuery({ search, offset: 0 })
  }

  // is loading
  if (['', 'initial', 'loading'].includes(status)) {
    return <Preloader show={true} />
  }

  if (['fail', 'error'].includes(status)) {
    return (
      <div className="mt-3 mb-3">
        <Alerts alerts={error} />
      </div>
    )
  }

  return (
    <>
      <div className="table-settings mb-2 mb-lg-4">
        <Row className="justify-content-between align-items-center">
            <Col xs={12} lg={4} className="d-flex py-2 pe-lg-3">
              <Form onSubmit={handleSearchSubmit} noValidate={true} className="w-100">
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Search review text" value={search} onChange={ event => {
                      setSearch(event.target.value);
                    }} />
                    <Button variant="outline-primary" type="submit">
                      Search
                    </Button>
                </InputGroup>
              </Form>
            </Col>

            <Col xs={12} lg={4} className="d-flex py-2 pe-lg-3">
                <ReactSelectAsync
                  Actions={partnerActions}
                  actionName="partnersList"
                  queryParams={null}
                  itemsPath="partners_data.partnersList"
                  fieldName="partner_title"
                  placeholder="All partners"
                  isClearable={true}
                  onChange={(item) => setQuery({ partnerId: item?.value ?? '', offset: 0 })}
                  selectedValue={partnerId}
                />
            </Col>

            <Col xs={12} lg={4} className="text-end py-2">
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm icon-gray">
                            <FontAwesomeIcon icon={faSlidersH} />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                        <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                        <Dropdown.Item className="d-flex fw-bold" onClick={() => setQuery({ limit: 10, offset: 0 })}>
                          10 
                          {limit === '10' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 20, offset: 0 })}>
                          20 
                          {limit === '20' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 50, offset: 0 })}>
                          50 
                          {limit === '50' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold" onClick={() => setQuery({ limit: 100, offset: 0 })}>
                          100 
                          {limit === '100' && (<span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>)}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
            <Table hover className="user-table align-items-center">
                <thead>
                    <tr>
                        <th className="border-bottom">Comment</th>
                        <th className="border-bottom">Score</th>
                        <th className="border-bottom">Images</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    _(items)
                    .map(function(item, i) {
                      const userName = (`${item?.member?.firstName ?? ''} ${item?.member?.lastName ?? ''}`).trim()
                      return (
                        <tr key={item._id} data-i={i}>
                            <td style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <Card.Link as={Link} to={Routes.Member.path.replace(':_id',item?.member?._id)} className="d-flex align-items-center justify-content-start">
                                    <Image src={item?.member?.image ?? userAvatar} className="user-avatar rounded-circle me-3" />
                                    <div className="d-block">
                                      <span className="fw-bold">{item?.member?.displayName || userName || 'No name'}</span>
                                    </div>
                                  </Card.Link>
                                </div>
                                <div>
                                  <div>
                                    <small><span className="fw-normal fst-italic">{moment(item.reviewDate).format('DD.MM.YYYY HH:mm')}</span></small>
                                  </div>
                                </div>
                              </div>

                              <div className="py-1">
                                <span className="fw-normal">
                                  {item?.product?.productTmbUrl && (
                                    <a href={item?.product?.productTmbUrl} target="_blank" rel="noopener noreferrer" className="fw-normal">
                                      <small className="fw-normal fst-italic">{item?.product?.productTitle}</small>
                                      <span className="icon icon-sm icon-gray px-2">
                                        <FontAwesomeIcon icon={faExternalLinkAlt} className="pl-1" style={{fontSize: '0.65rem'}} />
                                      </span>
                                    </a>
                                  )}
                                </span>
                              </div>
                              
                              <div className="py-1">
                                <Card.Link as={Link} to={Routes.Review.path.replace(':_id',item._id)}>
                                  <span className="fw-bold text-blue">{item.reviewTitle || 'No title'}</span>
                                </Card.Link>
                              </div>
                              <div className="py-1">
                                <span className="fw-normal">{item.reviewComment}</span>
                              </div>
                              <div className="py-1">
                                <Badge pill bg={item.reviewStatus === "Published" ? "secondary" : "warning"} text="primary" className="badge-md notification-count me-2">{item.reviewStatus.toLowerCase()}</Badge>
                              </div>
                            </td>
                            <td>
                              <span className="fw-normal">{item?.reviewScore?.$numberDecimal}</span>
                            </td>
                            <td>
                              <div>
                              <span className="fw-normal">{(item?.reviewImages || []).length} images</span>
                              </div>
                              {  _(item?.reviewImages || [])
                                  .map(function(imgItemUrl, i) {
                                    return (
                                      <a key={i} href={imgItemUrl} target="_blank" rel="noopener noreferrer" className="fw-normal">
                                        <Image src={imgItemUrl} width="60" thumbnail />  
                                      </a>
                                    )
                                  })
                                  .value()
                              }
                              
                            </td>
                        </tr>
                      );
                    })
                    .value()
                  }
                  {count === 0 && (
                    <tr>
                      <td colSpan="3"><span className="fw-normal">No entries to show</span></td>
                    </tr>
                  )}
                </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
                <PaginationTool query={query} setQuery={setQuery} collection={itemsListRes} />
            </Nav>
            <small className="fw-bold">
                Showing <b>{count}</b> out of <b>{countTotal}</b> entries
            </small>
            </Card.Footer>
        </Card.Body>
    </Card>
    </>
  );
}

export default Component;