import { combineReducers } from "redux";
import _ from "lodash";

import * as memberStatusesList from "./memberStatusesList";
import * as transactionTypesList from "./transactionTypesList";
import * as earningSettingTypesList from "./earningSettingTypesList";
import * as earningSettingStatusesList from "./earningSettingStatusesList";
import * as partnerStatusesList from "./partnerStatusesList";
import * as apiKeyTypesList from "./apiKeyTypesList";

const slices = {
    memberStatusesList,
    transactionTypesList,
    earningSettingTypesList,
    earningSettingStatusesList,
    partnerStatusesList,
    apiKeyTypesList,
};

const settingsTypes = _.mapValues(slices, (s) => s.slice.types );
const settingsActions = Object.assign({}, ...(_(slices).values().map('slice.actions').value()));
const settingsSagas = _(slices).values().map('slice.sagas').flatten().value()
const settingsSelectors = Object.assign({}, ...(_(slices).values().map('slice.selectors').value()));
const reducers = Object.assign({}, ...(_(slices).values().map('default').value()));

export {
    settingsTypes,
    settingsActions,
    settingsSagas,
    settingsSelectors,
};

export default combineReducers(reducers);